(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/url/helpers/url-helpers.js') >= 0) return;  svs.modules.push('/components/utils/url/helpers/url-helpers.js');

(function() {
  'use strict';

  var isServer = typeof exports === 'object';

  function registerHelper(hbs, url) {
    hbs.registerHelper('urlEncode', function(value) {
      return url.encode(value);
    });
  }

  if (isServer) {
    registerHelper(
      require('hbs'),
      require(__dirname + '/../assets/javascripts/url.js')
    );
  } else {
    registerHelper(Handlebars, svs.utils.url);
  }
})();


 })(window);