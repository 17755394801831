(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/url/assets/javascripts/url.js') >= 0) return;  svs.modules.push('/components/utils/url/assets/javascripts/url.js');

var svs = svs || {};

(function(svs, isServer) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.url = svs.utils.url || {};

  svs.utils.Url = function() {
    function getQuerystring() {
      if (isServer) {
        return [];
      }

      return (function(a) {
        if (a === '') {
          return {};
        }

        var b = {};
        for (var i = 0; i < a.length; ++i) {
          var p = a[i].split('=');
          if (p.length !== 2) {
            continue;
          }

          b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
        }

        return b;
      })(window.location.search.substr(1).split('&'));
    }

    return {
      queryString: (function() {
        return getQuerystring();
      })(),

      refreshQueryString: function() {
        svs.utils.url.queryString = getQuerystring();
      },

      encode: function(value) {
        value = value ? value : '';
        return value
          .replace(/[ÅÄåä]/g, 'a')
          .replace(/[Öö]/g, 'o')
          .replace(/Éé/g, 'e')
          .replace(/ /g, '-')
          .replace(/[^A-Za-z0-9_-]/g, '')
          .toLowerCase();
      },

      removeQueryString: function(url) {
        if (typeof url !== 'string' && typeof url !== 'undefined') {
          throw new Error('Argument must be of type string. Received `' + typeof url + '`');
        } else {
          url = window.location.href;
        }

        return url.split('?')[0];
      },

      updateQueryStringParameter: function(uri, key, value) {
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';

        if (uri.match(re)) {
          if (value) {
            return uri.replace(re, '$1' + key + '=' + value + '$2');
          }
          return uri.replace(re, '$1$2');
        }
        return uri + separator + key + '=' + value;
      },

      makeInternal: function(url, fallbackUrl) {
        fallbackUrl = fallbackUrl || '/';

        var validUrl =
          url && url.match(/^(^.*\/\/[^/]+)?(^.*\\[^\\]+)?(\/?[^<>:"]*)$/);

        return (
          validUrl && validUrl.length === 4 && validUrl[3] || fallbackUrl
        );
      },
      navigate: function(url) {
        if (url) {
          window.location.href = url;
        }
      },
      getLocation: function() {
        return window.location;
      },
      join: function(a, b) {
        if (a && b) {
          if (b.indexOf('?') === 0) {
            if (~a.indexOf('?')) {
              return a + '&' + b.substr(1);
            }
          } else if (b.indexOf('&') === 0) {
            if (!~a.indexOf('?')) {
              return a + '?' + b.substr(1);
            } else if (a.lastIndexOf('?') === a.length - 1) {
              return a + b.substr(1);
            }
          } else if (
            b.indexOf('/') === 0 &&
            a.lastIndexOf('/') + 1 === a.length
          ) {
            return a + b.substr(1);
          } else if (
            b.indexOf('/') !== 0 &&
            a.lastIndexOf('/') + 1 !== a.length
          ) {
            return a + '/' + b;
          }
          return a + b;
        }
        return a;
      },
      currentPathEncodedParams: function() {
        var returnUrl = window.location.pathname;
        var paramArray;
        var params = {};
        var separator = '?';
        var i;
        var iLen;
        var p;

        if (!isServer) {
          paramArray = window.location.search.substr(1).split('&');

          for (i = 0, iLen = paramArray.length; i < iLen; i++) {
            p = paramArray[i].split('=');
            if (p.length !== 2) {
              continue;
            }
            returnUrl +=
              separator +
              p[0] +
              '=' +
              encodeURIComponent(decodeURIComponent(p[1].replace(/\+/g, ' ')));
            if (i === 0) {
              separator = '&';
            }
            params[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
          }
        }
        return returnUrl;
      },
      currentUrlEncodedParams: function() {
        return (
          window.location.protocol +
          '//' +
          window.location.host +
          this.currentPathEncodedParams()
        );
      }
    };
  };

  svs.utils.url = new svs.utils.Url();

  if (isServer) {
    module.exports = {
      join: svs.utils.url.join,
      encode: svs.utils.url.encode,
      makeInternal: svs.utils.url.makeInternal
    };
  }
})(svs, typeof exports === 'object');


 })(window);